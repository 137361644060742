<template>
    <div class="flex flex-col" >
        <Display :payment_method="payment_method" />
    </div>
</template>

<script>
import moment from 'moment'
import Display from '../PaymentMethod'
export default {
    name: 'PaymentMethod',
    data: () => ({
        payment_method: false,
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        getPaymentMethod() {
            if(this.model) {
                this.$requests.get(`${this.model.route}/${this.model.id}/payment-method`,
                {
                    success: (re) => {
                        this.payment_method = re.data.item
                        return true
                    },
                })
            }
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        this.getPaymentMethod()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        Display,
    },
}
</script>
<style scoped>
</style>
