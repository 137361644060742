<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Subscription
        </h1>
      <p><strong>Date Created:</strong> {{getDate(item.created_at)}}</p>
      <p><strong>Date Updated:</strong> {{getDate(item.updated_at)}}</p>

      <router-link :to="{path:'/orders/'+item.order_id}" style="text-decoration: underline;">
        Go To Order
      </router-link>

      <br>

        <ButtonTemplate :on_click="cancel" class="my-6">
            Cancel Subscription
        </ButtonTemplate>

        <SlideoutTabs default_width="half" :model="{id:item.id,type:'Subscription',route:`/subscription`}" :contents="tab_content" orientation="top-0 bottom-0 right-0" position="fixed">
        </SlideoutTabs>
        <div class="flex w-full">
            <div class="w-1/2" v-if="item">
                <FormTemplate class="p-2" @response="formResponse" button="Save" method="post" action="/subscription" :formdata="formData">
                    <TextInput type="text" label="Amount" v-model="item.amount" :required="false" ></TextInput>
                    <SelectInput :disabled="true" label="Status" v-model="item.status" :options="[{label:'Active',value:'Active'},{label:'Inactive',value:'Inactive'},{label:'Terminated',value:'Terminated'},{label: 'Canceled',value:'Canceled'},{label: 'Cancelled',value:'Cancelled'}, {label: 'Suspended',value:'Suspended'}]" :required="false" ></SelectInput>
                    <TextInput :disabled="true" type="text" label="Unit" v-model="item.unit" :required="false" ></TextInput>
                    <TextInput :disabled="true" type="text" label="Unit Length" v-model="item.unit_length" :required="false" ></TextInput>

                    <TextInput :disabled="true" type="text" label="Duration" v-model="item.duration" :required="false" ></TextInput>

                    <TextInput :disabled="true" type="text" label="Invoice" v-model="item.invoice" :required="false" ></TextInput>

                    <TextInput :disabled="true" type="text" label="Gateway ID" v-model="item.gateway_id" :required="false" ></TextInput>

                    <TextInput type="text" label="UID" v-model="item.uid" :required="false" ></TextInput>
                    <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.account" :optiondisplay="accountDisplay" :hideSelected="false"></SearchSelectInput>


                </FormTemplate>
            </div>
            <div class="w-1/2">
                <div class="p-2">
                    <AuthDotNetSub 
                        :subscription="authdotnet" 
                        :order="item.order" 
                        :remediation_transaction_id="item.remediation_transaction_id"
                        :subscription_id="item.id"
                        :account_id="item.account_id"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
import Notes from '../../../components/dashboard/Notes'
import AuthDotNetSub from '../../../components/dashboard/subscription/authdotnet/List'
import Licenses from '../../../components/dashboard/licenses/List'
import Products from '../../../components/dashboard/products/List'
import PaymentMethod from '../../../components/dashboard/paymentmethods/View'

export default {
    name: 'Subscription',
    mixins: [View],
    data: () => ({
        parameter: 'subscription_id',
        action: '/subscription/',
        tab_content: {
            'licenses': {
                label: 'Licenses',
                icon: '/assets/licenses.png',
                component: Licenses,
                top: '200px',
            },
            'products': {
                label: 'Products',
                icon: '/assets/products.png',
                component: Products,
                top: '300px',
            },
            'payment_method': {
                label: 'Payment Method',
                icon: '/assets/payment_methods.png',
                component: PaymentMethod,
                top: '400px',
            },
            'notes': {
                label: 'Notes',
                icon: '/assets/notes.png',
                component: Notes,
                top: '500px',
            },
        },
        authdotnet: false,
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email','phone'],
            'action': '/search/',
        },
    }),
    computed: {
        formData() {
            return {
                'subscription':this.item,
            }
        },
    },
    props: [],
    methods: {
        cancel() {
            let that = this;
            this.$requests.get(`/subscriptions/cancel/${this.item.id}`,
            {
                success: (re) => {
                    console.log("cancelled "+re.data);
                    that.item.status = "Cancelled";
                },
            })
        },
        accountSelected(item) {
            this.$set(this.item,'account_id',item.item.id)
            this.$set(this.item,'account',item.item)
        },
        accountRemoved() {
            this.$delete(this.item,'account_id')
            this.$delete(this.item,'account')
        },
        AgencyClick() {
            this.$router.push({path:'/agencies/'+this.item.account_id})
        },
        accountDisplay(item) {
            return 'ID: '+item.id+' - Name: '+item.name
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
        itemFetched() {
            if(this.item && this.item.uid && this.item.uid.trim() != ''){
                this.$requests.get(`/subscription/${this.item.id}/authdotnet/subscription`,
                {
                    success: (re) => {
                        this.authdotnet = re.data.item
                        return true
                    },
                })
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        AuthDotNetSub,
    },
}
</script>
<style scoped>
</style>
