<template>
<div class="my-2">
    <div v-if="subscription" class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
            <h3 class="text-md leading-6 font-medium text-gray-900">
            Authorize.Net Details
            </h3>
        </div>
        <div class="border-t border-gray-200">
            <dl>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Status
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.status}}
                    </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Amount
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.amount}}
                    </dd>
                </div>
                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Description
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.description}}
                    </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Name
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.name}}
                    </dd>
                </div>
                
                
                
                <div v-if="subscription.payment_schedule" class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-medium text-gray-900">
                            Payment Schedule
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Unit Length
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.interval.length}}
                                    </dd>
                                </div>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Unit
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.interval.unit}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Start Date
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.startDate}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Total Occurences 
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.totalOccurrences}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Trial Occurences 
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.trialOccurrences}}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-bold text-gray-900">
                            Transactions
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div v-if="remediation_transaction_id" class="bg-white px-4 py-5">
                                    <dt class="text-sm font-bold text-gray-500 mb-3">
                                        Remediation Transaction
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <TransactionDetails
                                            :transaction_id="remediation_transaction_id"
                                            :remediation_transaction="true"
                                        >
                                        </TransactionDetails>
                                    </dd>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <InvoiceBtn 
                                            :transaction_id="remediation_transaction_id"
                                        ></InvoiceBtn>
                                    </dd>
                                </div>

                                <div v-if="subscription.transactions" class="arb-box bg-white px-4 py-5 text-sm font-medium text-gray-500">
                                    <span class="font-bold">
                                        ARB Transactions
                                    </span>
                                    <div v-for="(transaction,i) in subscription.transactions" :key="i" class="bg-white px-4 py-5">
                                        
                                        <TransactionDetails
                                            :transaction_id="transaction.transId"
                                            :time_submitted="transaction.submitTimeUTC"
                                            :transaction_attempts="transaction.attemptNum"
                                            :payment_number="transaction.payNum"
                                            :status="transaction.response"
                                        >
                                        </TransactionDetails>
                                        
                                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <InvoiceBtn 
                                                v-if="transaction.transId"
                                                :transaction_id="transaction.transId"
                                                :subscription_id="subscription_id"
                                                :account_id="account_id"
                                            ></InvoiceBtn>
                                            
                                        </dd>
                                    </div>
                                </div>
                                <div v-else class="arb-box bg-white px-4 py-5 text-sm font-medium text-gray-500">
                                    No ARB Transactions Yet
                                </div>

                                <div v-if="order && order.initial_transaction_id != null" class="bg-white px-4 py-5">
                                    <dt class="text-sm font-bold text-gray-500 mb-3">
                                        Initial Transaction
                                    </dt>
                                    <TransactionDetails
                                        :transaction_id="order.initial_transaction_id"
                                        :initial_transaction="true"
                                    >
                                    </TransactionDetails>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <InvoiceBtn 
                                            :transaction_id="order.initial_transaction_id"
                                        ></InvoiceBtn>
                                    </dd>
                                </div>
                                <div v-else class="bg-white px-4 py-5">
                                    <dt class="text-sm font-bold text-gray-500">
                                        Initial Transaction Not Found
                                    </dt>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </dl>
        </div>
    </div>
    <div v-else>Loading..</div>
</div>
</template>

<script>
import moment from 'moment'
import InvoiceBtn from './InvoiceBtn.vue';
import TransactionDetails from './TransactionDetails.vue';

export default {
    name: 'AuthDotNetSubscriptionList',
    data: () => ({
    }),
    computed: {
    },
    props: [
        'subscription', 
        'order', 
        'remediation_transaction_id',
        'subscription_id',
        'account_id'
    ],
    mixins: [],
    methods: {
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        InvoiceBtn,
        TransactionDetails
    },
}
</script>
<style scoped>
.arb-box{
    max-height:500px;
    overflow: auto;
    border-top:1px solid gray;
    border-bottom:1px solid gray;
}
</style>
